import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { shouldForwardProp } from "../../utils";

type PpPageContentContainer = {
  $showProgressBar: boolean;
};

export const PageContentContainer = styled("div", {
  shouldForwardProp,
})<PpPageContentContainer>`
  ${({ $showProgressBar, theme }): SerializedStyles => css`
    position: relative;
    ${$showProgressBar
      ? css`
          margin-top: calc(
            -${theme.cssMixins.appHeaderHeightMobile} - 2 * ${theme.cssMixins.progressBarHeight}
          );
          ${theme.breakpoints.up("sm")} {
            margin-top: calc(
              -${theme.cssMixins.appHeaderHeightDesktop} - 2 * ${theme.cssMixins.progressBarHeight}
            );
          }
        `
      : css`
          margin-top: calc(-${theme.cssMixins.appHeaderHeightMobile});
          ${theme.breakpoints.up("sm")} {
            margin-top: calc(-${theme.cssMixins.appHeaderHeightDesktop});
          }
        `};
    min-height: 100vh;
    background-color: ${theme.palette.background.default};
  `}
`;

import React, { useState } from "react";
import { ButtonProps, Fade } from "@mui/material";
import { AlertBar, PpAlertBar } from "../alertbar";
import { ProgressBar } from "../bars";
import { Button } from "../buttons";
import { useDisableBodyScroll } from "../hooks";
import { MenuCloseIcon, MenuOpenIcon } from "../icons";
import {
  CTALink,
  HeaderDesktop,
  HeaderMobile,
  HeaderMobileLeft,
  LogoLink,
  MenuContainer,
  MenuHeader,
  MobileMenu,
  NavItemsListDesktop,
  NavItemsListMobile,
  StyledAppBar,
  StyledToolbar,
} from "./styles";

type CTAButton = {
  label: string;
  url: string;
  variant: {
    desktop: ButtonProps["variant"];
    mobile: ButtonProps["variant"];
  };
};

export type PpHeader = {
  alertBar: PpAlertBar | null;
  atTop: boolean;
  ctaButton: CTAButton;
  isLogoOverlapping?: boolean;
  logo: {
    alt: string;
    default: string;
    menuLogo: string;
  };
  showProgressBar: boolean;
  transparentBg: boolean;
};

type PpMenuContent = {
  ctaButton: CTAButton;
  logo: {
    alt: string;
    src: string;
  };
  showProgressBar: boolean;
  toggleMenu: () => void;
};

function MenuContent({
  children,
  ctaButton,
  logo,
  showProgressBar,
  toggleMenu,
}: PpWC<PpMenuContent>): React.ReactElement {
  return (
    <MenuContainer>
      <MenuHeader $progressBar={showProgressBar}>
        <HeaderMobileLeft>
          <MenuCloseIcon fontSize="large" onClick={toggleMenu} />
          <LogoLink to="/">
            <img alt={logo.alt} src={logo.src} />
          </LogoLink>
        </HeaderMobileLeft>
        <CTALink to={ctaButton.url}>
          <Button color="secondary" variant={ctaButton.variant.mobile}>
            {ctaButton.label}
          </Button>
        </CTALink>
      </MenuHeader>
      <NavItemsListMobile>{children}</NavItemsListMobile>
    </MenuContainer>
  );
}

export function Header({
  alertBar,
  atTop,
  children,
  ctaButton,
  isLogoOverlapping = false,
  logo,
  showProgressBar,
  transparentBg,
}: PpWC<PpHeader>): React.ReactElement {
  const [menuOpen, setMenuOpen] = useState(false);
  useDisableBodyScroll(menuOpen);

  function toggleMenu(): void {
    setMenuOpen((prev) => !prev);
  }

  const isTransparentBg = transparentBg && atTop;

  const color = isTransparentBg ? "transparent" : "inherit";
  return (
    <React.Fragment>
      {alertBar && <AlertBar {...alertBar} />}
      <StyledAppBar
        $transparentBg={isTransparentBg}
        color={color}
        elevation={0}
      >
        <StyledToolbar
          $progressBar={showProgressBar}
          $transparentBg={isTransparentBg}
        >
          <HeaderDesktop>
            <LogoLink
              $isLogoOverlapping={isLogoOverlapping}
              $progressBar={showProgressBar}
              to="/"
            >
              <img alt={logo.alt} src={logo.default} />
            </LogoLink>
            <NavItemsListDesktop>
              {children}
              <CTALink to={ctaButton.url}>
                <Button color="secondary" variant={ctaButton.variant.desktop}>
                  {ctaButton.label}
                </Button>
              </CTALink>
            </NavItemsListDesktop>
          </HeaderDesktop>
          <HeaderMobile>
            <HeaderMobileLeft>
              <MenuOpenIcon
                $transparentBg={isTransparentBg}
                fontSize="large"
                onClick={toggleMenu}
              />
              <LogoLink
                $isLogoOverlapping={isLogoOverlapping}
                $progressBar={showProgressBar}
                to="/"
              >
                <img alt={logo.alt} src={logo.default} />
              </LogoLink>
            </HeaderMobileLeft>
            {!isTransparentBg && (
              <CTALink to={ctaButton.url}>
                <Button color="secondary" variant={ctaButton.variant.mobile}>
                  {ctaButton.label}
                </Button>
              </CTALink>
            )}
          </HeaderMobile>
          <Fade in={menuOpen} timeout={300}>
            <MobileMenu>
              <MenuContent
                ctaButton={ctaButton}
                logo={{ alt: logo.alt, src: logo.menuLogo }}
                showProgressBar={showProgressBar}
                toggleMenu={toggleMenu}
              >
                {children}
              </MenuContent>
            </MobileMenu>
          </Fade>
        </StyledToolbar>
        {showProgressBar && <ProgressBar show={!isTransparentBg} />}
      </StyledAppBar>
    </React.Fragment>
  );
}

import { useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

export function useScrollAtTop(value = 40): boolean {
  const [atTop, setAtTop] = useState<boolean>(true);

  useScrollPosition(
    ({ currPos }) => {
      if (Math.abs(currPos.y) > value && atTop) setAtTop(false);
      if (Math.abs(currPos.y) <= value && !atTop) setAtTop(true);
    },
    [atTop]
  );

  return atTop;
}

import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import { shouldForwardProp } from "../utils";

type PpTransparentBg = {
  $transparentBg: boolean;
};

export const MenuOpenIcon = styled(MenuIcon, {
  shouldForwardProp,
})<PpTransparentBg>`
  ${({ $transparentBg, theme }): SerializedStyles => css`
    transition: ${theme.transitions.create(["color"])};
    color: ${$transparentBg
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main};
  `};
`;

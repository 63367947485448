import React from "react";
import { PageContentContainer } from "./styles";

type PpPageContent = {
  showProgressBar: boolean;
};

export function PageContent({
  children,
  showProgressBar,
}: PpWC<PpPageContent>): React.ReactElement {
  return (
    <PageContentContainer $showProgressBar={showProgressBar}>
      {children}
    </PageContentContainer>
  );
}

import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";

export const MenuCloseIcon = styled(CloseIcon)`
  ${({ theme }): SerializedStyles => css`
    color: ${theme.palette.primary.main};
    transition: ${theme.transitions.create("color", {
      duration: theme.transitions.duration.shorter,
    })};
    &:hover {
      color: ${theme.palette.primary.light};
    }
  `}
`;

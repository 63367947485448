import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "@mui/material";
import { shouldForwardProp } from "../utils";

type PpHighlighted = {
  $highlighted: boolean;
};

export const MuiLink = styled(Link, { shouldForwardProp })<PpHighlighted>`
  ${({ $highlighted, theme }): SerializedStyles => css`
    ${$highlighted &&
    css`
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 50%,
        ${theme.palette.primary.light} 50%
      );
    `};
  `}
`;

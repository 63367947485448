import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  Typography,
} from "@mui/material";

export type PpSelect = Omit<MuiSelectProps, "onChange"> & {
  darkMode?: boolean;
  defaultValue?: string;
  id: string;
  label?: string;
  name: string;
  onChange: (
    e: React.ChangeEvent<{ name?: string | undefined; value: string }>
  ) => void;
  placeholder?: string;
  showLabel?: boolean;
  values: Array<{
    label: string;
    value: string;
  }>;
};

export function Select({
  darkMode = false,
  defaultValue = "",
  id,
  label,
  name,
  onChange,
  placeholder,
  showLabel = true,
  values,
  ...rest
}: PpSelect): React.ReactElement {
  const [value, setValue] = useState<string>(defaultValue);
  const [isOpen, setOpen] = useState<boolean>(false);

  function handleChange(
    e: React.ChangeEvent<{ name?: string | undefined; value: string }>
  ): void {
    onChange(e);
    setValue(e.target.value);
  }

  const classes = `select-field ${darkMode ? "darkMode" : ""}`;

  return (
    <FormControl {...rest} className={classes}>
      {showLabel ? (
        <InputLabel shrink id={`${name}-label`}>
          {isOpen || value !== "" ? placeholder : null}
        </InputLabel>
      ) : null}
      <MuiSelect
        disableUnderline
        displayEmpty
        IconComponent={ExpandMoreIcon}
        id={id}
        labelId={`${name}-label`}
        MenuProps={{
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom",
          },
          getContentAnchorEl: null,
          transformOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        }}
        name={name}
        open={isOpen}
        renderValue={
          value !== ""
            ? undefined
            : (): JSX.Element => (
                <Typography variant="select">{placeholder}</Typography>
              )
        }
        value={value}
        onChange={handleChange}
        onClose={(): void => setOpen(false)}
        onOpen={(): void => setOpen(true)}
      >
        {values.map((v) => (
          <MenuItem key={v.value} value={v.value}>
            {v.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import { Link } from "../links";

export const Container = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.respTextAlign};
  `}
`;

export const Heading = styled(Typography)`
  ${({ theme }): SerializedStyles => css`
    margin-bottom: ${theme.spacing(1.5)};
  `}
`;

export const StyledLink = styled(Link)`
  ${({ theme }): SerializedStyles => css`
    width: fit-content;
    ${theme.breakpoints.down("sm")} {
      padding: ${theme.spacing(1.25, 0)};
    }
  `}
`;

export const LinkWrapper = styled("span")`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.rowVCentered};
    margin-bottom: ${theme.spacing(0.5)};
    ${theme.breakpoints.down("sm")} {
      margin-bottom: 0;
    }
  `}
`;

export const Label = styled(Typography)`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.noSelect};
    border-radius: ${theme.shape.siteBorderRadius.xs};
    padding: ${theme.spacing(0.25, 1)};
    background-color: ${theme.palette.secondary.main};
    margin-left: ${theme.spacing(1)};
  `}
`;

import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { IconButton, Toolbar } from "@mui/material";
import { Link } from "../links";

export const Container = styled(Toolbar)`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.moveDownAnimation({ duration: 300 })};
    ${theme.cssMixins.alertBarHeight};
    position: static;
    ${theme.cssMixins.stretchTop};
    z-index: 6000;
    min-height: 0 !important;
    ${theme.cssMixins.rowHCentered};
    background-color: ${theme.palette.secondary.main};
    color: ${theme.palette.site.alertBar};
    ${theme.cssMixins.respTextAlign};
  `}
`;

export const AlertBarLink = styled(Link)`
  ${({ theme }): SerializedStyles => css`
    margin-left: ${theme.spacing(1)};
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const AlertBarCloseIcon = styled(IconButton)`
  ${({ theme }): SerializedStyles => css`
    position: absolute;
    right: 0;
    opacity: 1;
    padding: ${theme.spacing(1.5)};
    &:hover {
      background: none;
      opacity: 0.6;
      transition: ${theme.transitions.create("opacity", {
        duration: theme.transitions.duration.shorter,
      })};
    }
    svg {
      color: ${theme.palette.site.alertBar} !important;
    }
  `}
`;

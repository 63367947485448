import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { shouldForwardProp } from "../utils";

type PpShow = {
  $show: boolean;
};

export const BarContainer = styled("div", { shouldForwardProp })<PpShow>`
  ${({ $show, theme }): SerializedStyles => css`
    background-color: ${theme.palette.background.paper};
    height: ${theme.cssMixins.progressBarHeight};
    width: 100vw;
    opacity: ${$show ? "1" : "0"};
    transition: ${theme.transitions.create(["opacity"])};
  `}
`;

type PpProgress = {
  $progress: string;
};

export const Progress = styled("div", { shouldForwardProp })<PpProgress>`
  ${({ $progress, theme }): SerializedStyles => css`
    background-color: ${theme.palette.primary.light};
    height: 4px;
    width: 100%;
    transform-origin: left;
    transform: translate3d(${$progress}, 0, 0);
  `}
`;

import { graphql, useStaticQuery } from "gatsby";
import { HeaderQuery } from "./query.generated";

export type SiteHeaderData = HeaderQuery["cms"]["site"];

export function useStaticHeaderQuery(): SiteHeaderData {
  const data = useStaticQuery<HeaderQuery>(
    graphql`
      query Header {
        cms {
          site(hostname: "www.italymondo.com") {
            companydetail {
              siteLogoDefault {
                url
              }
              siteLogoMobile {
                url
              }
            }
            siteName
            mainmenu {
              ctaLink {
                url
              }
              ctaTitle
              mainMenu {
                ... on CMS_MainMenuItemBlock {
                  __typename
                  id
                  page {
                    url
                  }
                  subMenuItems {
                    ... on CMS_MenuSummaryBlock {
                      __typename
                      id
                      linkText
                      page {
                        url
                      }
                    }
                    ... on CMS_MenuPageListBlock {
                      __typename
                      id
                      pages {
                        ... on CMS_MenuPageBlock {
                          __typename
                          icon {
                            url
                          }
                          title
                          description
                          page {
                            url
                          }
                        }
                      }
                    }
                  }
                  title
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useEffect, useState } from "react";

let intersectionObserver: IntersectionObserver;
let intersectionObserverOptions = {};
const subscribers = new WeakMap();

type TpIntersectionObserverOptions = {
  root?: Element | null;
  rootMargin?: string;
  thresholds?: ReadonlyArray<number>;
};

const handleIntersections = (entries: any[]): void =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  entries.forEach((entry) => subscribers?.get(entry.target)?.call(null, entry));

const getIntersectionObserver = (): IntersectionObserver => {
  if (!intersectionObserver) {
    intersectionObserver = new IntersectionObserver(
      handleIntersections,
      intersectionObserverOptions
    );
  }

  return intersectionObserver;
};

const setIntersectionObserverOptions = (
  options: TpIntersectionObserverOptions
): void => {
  if (intersectionObserver) {
    return;
  }

  intersectionObserverOptions = options;
};

const unwatch = (domNode: HTMLElement): void => {
  intersectionObserver.unobserve(domNode);
  subscribers.delete(domNode);
};
const watch = (
  domNode: HTMLElement | null,
  callback: (...args: any[]) => void
): void | (() => void) => {
  if (!domNode || subscribers.has(domNode)) {
    return;
  }

  subscribers.set(domNode, callback);
  getIntersectionObserver().observe(domNode);

  // eslint-disable-next-line consistent-return
  return (): void => unwatch(domNode);
};

// eslint-disable-next-line @typescript-eslint/ban-types
const getSubscribers = (): WeakMap<object, any> => subscribers;

const VO = {
  getSubscribers,
  setIntersectionObserverOptions,
  unwatch,
  watch,
};

export function useHasBeenAlmostVisible(
  nodeRef: React.RefObject<HTMLElement>,
  rootMargin = "0px 0px 1000px 0px"
): boolean {
  const [isVisible, setVisible] = useState(false);

  function handleVisibilityChange({
    isIntersecting,
  }: {
    isIntersecting: boolean;
  }): void {
    if (isIntersecting === true) {
      setVisible(isIntersecting);
    }
  }

  useEffect(() => {
    setIntersectionObserverOptions({ rootMargin });
    return VO.watch(nodeRef.current, handleVisibilityChange);
  }, [nodeRef, rootMargin]);

  return isVisible;
}

import React from "react";
import { useScrollProgress } from "../hooks";
import { BarContainer, Progress } from "./styles";

type PpProgressBar = {
  show: boolean;
};

export function ProgressBar({ show }: PpProgressBar): React.ReactElement {
  const pageScrollProgress = useScrollProgress();

  return (
    <BarContainer $show={show}>
      <Progress $progress={pageScrollProgress} />
    </BarContainer>
  );
}

import React from "react";
import { PageContent } from "@italymondo/core-ui";
import { Footer } from "./Footer";
import { Header, PpAlertBar } from "./Header";

type PpLayout = {
  alertBar?: PpAlertBar | null;
  showProgressBar?: boolean;
  transparentHeader?: boolean;
};

export function Layout({
  alertBar,
  children,
  showProgressBar = false,
  transparentHeader = true,
}: PpLayout & {
  children?: React.ReactNode;
}): React.ReactElement {
  return (
    <React.Fragment>
      <Header
        alertBar={alertBar}
        showProgressBar={showProgressBar}
        transparent={transparentHeader}
      />
      <PageContent showProgressBar={showProgressBar}>{children}</PageContent>
      <Footer />
    </React.Fragment>
  );
}

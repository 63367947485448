import React from "react";
import { GridProps, Stack } from "@mui/material";
import { RichText } from "../textFields";
import {
  Container,
  Heading,
  Label,
  LinkWrapper,
  StyledLink,
} from "./FooterColumnBlock.styles";

export type PpFooterColumnBlockItem = {
  label?: string;
  link?: string;
  text: string;
};

export type PpFooterColumnBlock = GridProps & {
  heading: string;
  items: Array<PpFooterColumnBlockItem> | null;
};

export function FooterColumnBlock({
  heading,
  items,
  textAlign = "inherit",
  alignItems = { md: "flex-start", xs: "center" },
  ...rest
}: PpFooterColumnBlock): React.ReactElement {
  return (
    <Container {...rest}>
      <Stack alignItems={alignItems}>
        <Heading
          gutterBottom
          align={textAlign}
          color="primary.light"
          variant="footer--heading"
        >
          {heading}
        </Heading>
        {Array.isArray(items) &&
          items.map((item) =>
            item.link ? (
              <LinkWrapper key={item.text}>
                <StyledLink
                  align={textAlign}
                  color="primary.contrastText"
                  to={item.link}
                  variant="footer--body"
                >
                  {item.text}
                </StyledLink>
                {item.label && (
                  <Label color="primary.contrastText" variant="footer--label">
                    {item.label}
                  </Label>
                )}
              </LinkWrapper>
            ) : (
              <RichText
                key={item.text}
                gutterBottom
                align={textAlign}
                color="primary.contrastText"
                text={item.text}
                variant="footer--body"
              />
            )
          )}
      </Stack>
    </Container>
  );
}

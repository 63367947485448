/* eslint-disable sort-keys-fix/sort-keys-fix */
import React, { useRef } from "react";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { FooterColumnBlock } from "../../blocks";
import { Container } from "../../containers";
import { useHasBeenAlmostVisible } from "../../hooks";
import {
  BottomContainer,
  BottomSection,
  CopyrightSection,
  CopyrightText,
  DashedLine,
  FooterContainer,
  IconLink,
  LegalLink,
  LoadingSpinner,
  Logo,
  MainContentBlock,
  MainInfoBlock,
  Memberships,
  Sites,
  StyledSelect,
} from "./styles";

export type PpFooterV1 = {
  copyrightText: string;
  currentSite: string;
  description: string;
  legalIndexPage?: {
    title: string;
    url: string;
  };
  locations: Array<{
    country: string;
    fullAddress: string;
  }> | null;
  logo: {
    alt: string;
    src: string;
  };
  memberships: Array<{
    src: string;
    url: string;
  }> | null;
  sites: Array<{
    siteName: string;
    url: string;
  }> | null;
  social: {
    facebook?: string;
    instagram?: string;
    twitter?: string;
  };
};

export function FooterV1({
  children,
  copyrightText,
  currentSite,
  description,
  legalIndexPage,
  locations,
  logo,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  memberships,
  sites,
  social,
}: PpWC<PpFooterV1>): React.ReactElement {
  const elementRef = useRef<HTMLDivElement>(null);
  const shouldRenderContent = useHasBeenAlmostVisible(elementRef);

  function renderMainInfoBlock(): React.ReactElement {
    return (
      <MainInfoBlock
        container
        item
        direction="column"
        md={4}
        sm={6}
        spacing={3}
        xs={12}
      >
        <Grid item>
          <Logo>
            {shouldRenderContent ? <img alt={logo.alt} src={logo.src} /> : null}
          </Logo>
        </Grid>
        <Grid item>
          <Typography color="primary.contrastText" variant="body1">
            {description}
          </Typography>
        </Grid>
        <Grid container item spacing={1}>
          {Array.isArray(locations) &&
            locations.map((location) => (
              <FooterColumnBlock
                key={location.country}
                item
                heading={location.country}
                items={[{ text: location.fullAddress }]}
                xs={6}
              />
            ))}
        </Grid>
        <Grid
          container
          item
          justifyContent={{ xs: "center", md: "flex-start" }}
          spacing={2}
        >
          {social.instagram ? (
            <Grid item>
              <IconLink to={social.instagram}>
                <Instagram titleAccess="Instagram" />
              </IconLink>
            </Grid>
          ) : null}
          {social.twitter ? (
            <Grid item>
              <IconLink to={social.twitter}>
                <Twitter titleAccess="Twitter" />
              </IconLink>
            </Grid>
          ) : null}
          {social.facebook ? (
            <Grid item>
              <IconLink to={social.facebook}>
                <Facebook titleAccess="Facebook" />
              </IconLink>
            </Grid>
          ) : null}
        </Grid>
      </MainInfoBlock>
    );
  }

  function handleSiteClick(e: React.ChangeEvent<{ value: unknown }>): void {
    if (!Array.isArray(sites)) {
      return;
    }
    const redirectSite = sites.find((site) => site.siteName === e.target.value);
    if (redirectSite) {
      window.open(redirectSite.url, "_blank");
    }
  }

  const tempMemberships = ["mem1", "mem2", "mem3"];

  return (
    <FooterContainer ref={elementRef}>
      <Container>
        <Grid container direction={{ xs: "column", sm: "row" }}>
          {renderMainInfoBlock()}
          <Grid item sx={{ display: { xs: "flex", sm: "none" } }}>
            <DashedLine />
          </Grid>
          <MainContentBlock item md={8} sm={6} xs={12}>
            {children}
          </MainContentBlock>
        </Grid>
      </Container>
      <BottomContainer>
        <BottomSection
          alignItems="center"
          direction={{ xs: "column-reverse", md: "row" }}
          justifyContent="space-between"
        >
          {shouldRenderContent ? (
            <Memberships
              alignItems="center"
              direction="row"
              justifyContent={{ xs: "center", md: "flex-start" }}
              spacing={3}
            >
              {/* {Array.isArray(memberships) &&
                memberships.map((m) => <img key={m.src} alt="" src={m.src} />)} */}
              {tempMemberships.map((m) => (
                <span className={`icon-${m}`} />
              ))}
            </Memberships>
          ) : (
            <LoadingSpinner
              alignItems="center"
              direction="row"
              justifyContent={{ xs: "center", md: "flex-start" }}
            >
              <CircularProgress
                aria-label="memberships-loading"
                color="primary"
              />
            </LoadingSpinner>
          )}
          <CopyrightSection
            alignItems="center"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <CopyrightText color="primary.contrastText" variant="footer--legal">
              {copyrightText}
            </CopyrightText>
            {legalIndexPage ? (
              <LegalLink
                color="primary.contrastText"
                to={legalIndexPage.url}
                variant="footer--legal"
              >
                {legalIndexPage.title}
              </LegalLink>
            ) : null}
          </CopyrightSection>
          {Array.isArray(sites) && (
            <Sites
              alignItems="center"
              direction="row"
              justifyContent={{ xs: "center", md: "flex-end" }}
              spacing={2}
            >
              <Typography color="primary.contrastText" variant="body1">
                Our sites:
              </Typography>
              <StyledSelect
                defaultValue={currentSite}
                id="sites-select"
                name="sites-select"
                showLabel={false}
                values={sites.map((site) => ({
                  label: site.siteName,
                  value: site.siteName,
                }))}
                onChange={handleSiteClick}
              />
            </Sites>
          )}
        </BottomSection>
      </BottomContainer>
    </FooterContainer>
  );
}

import React from "react";
import { LinkProps } from "@mui/material";
import { Link as GatsbyLink } from "gatsby";
import { MuiLink } from "./styles";

export type PpLink = LinkProps & {
  highlighted?: boolean;
  to: string;
  withArrow?: boolean;
};

export function Link({
  children,
  color = "inherit",
  highlighted = false,
  to,
  variant = "inherit",
  withArrow = false,
  ...rest
}: PpWC<PpLink>): React.ReactElement {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.

  // Use Gatsby Link component for internal links,
  // MaterialUI Link component for external links

  function renderLinkContent(): React.ReactNode | Element {
    return withArrow ? (
      <span>
        {children}
        {` `}
        <span className="link-arrow">&rarr;</span>
      </span>
    ) : (
      children
    );
  }

  const internal = /^\/(?!\/)/.test(to);

  if (internal) {
    return (
      <MuiLink
        $highlighted={highlighted}
        color={color}
        component={GatsbyLink}
        to={to}
        underline="hover"
        variant={variant}
        {...rest}
      >
        {renderLinkContent()}
      </MuiLink>
    );
  }

  const sectionNavigation = /^#(?!\/)/.test(to);

  return (
    <MuiLink
      $highlighted={highlighted}
      color={color}
      href={to}
      rel={!sectionNavigation ? "noreferrer" : undefined}
      target={!sectionNavigation ? "_blank" : undefined}
      variant={variant}
      {...rest}
    >
      {renderLinkContent()}
    </MuiLink>
  );
}

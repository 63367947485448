import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  Box,
  Fade,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Arrow,
  ContentContainer,
  DesktopContainer,
  MenuLabel,
  MenuLabelWrapper,
  MenuWrapper,
  MobileContainer,
  StyledAccordionSummary,
  StyledBackdrop,
  StyledPopper,
} from "./styles";

export type PpHeaderMenu = {
  fullWidth: boolean;
  isMenuOpen?: boolean;
  label: string;
  showProgressBar: boolean;
};

export function HeaderMenu({
  children,
  fullWidth,
  isMenuOpen = false,
  label,
  showProgressBar,
}: PpWC<PpHeaderMenu>): React.ReactElement {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const anchorRef = useRef(null);
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  function handleExpandAccordion(
    _: React.SyntheticEvent<Element, Event>,
    expanded: boolean
  ): void {
    setOpen(expanded);
  }

  function handleOpen(): void {
    if (!isMobile) {
      setOpen(true);
    }
  }

  function handleClose(): void {
    if (!isMobile) {
      setOpen(false);
    }
  }

  useEffect(() => {
    if (isMenuOpen && isMobile) {
      setOpen(true);
    }
  }, [isMenuOpen, isMobile]);

  return (
    <React.Fragment>
      <MobileContainer>
        <Accordion
          disableGutters
          square
          elevation={0}
          expanded={open}
          onChange={handleExpandAccordion}
        >
          <StyledAccordionSummary
            aria-controls={`${label}-content`}
            id={`${label}-id`}
          >
            <MenuLabelWrapper $open={open} $transparentBg={!fullWidth}>
              <MenuLabel ref={anchorRef}>{label}</MenuLabel>
              <ExpandMoreIcon />
            </MenuLabelWrapper>
          </StyledAccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      </MobileContainer>
      <DesktopContainer>
        <Box sx={{ display: { sm: "block", xs: "none" } }}>
          <StyledBackdrop open={open} />
        </Box>
        <MenuWrapper>
          <MenuLabelWrapper
            $open={open}
            $transparentBg={!fullWidth}
            aria-haspopup="true"
            aria-owns="header-menu-mouse-over"
            variant="navItem"
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
          >
            <MenuLabel ref={anchorRef}>{label}</MenuLabel>
            <ExpandMoreIcon />
          </MenuLabelWrapper>
          <StyledPopper
            disablePortal
            transition
            anchorEl={anchorRef.current}
            modifiers={[
              {
                enabled: true,
                name: "arrow",
                options: {
                  element: arrowRef,
                },
              },
            ]}
            open={open}
            placement="bottom"
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
          >
            {({ TransitionProps }): React.ReactElement => (
              <Fade {...TransitionProps} timeout={350}>
                <div>
                  <Arrow ref={setArrowRef} $showProgressBar={showProgressBar} />
                  <ContentContainer $showProgressBar={showProgressBar}>
                    {children}
                  </ContentContainer>
                </div>
              </Fade>
            )}
          </StyledPopper>
        </MenuWrapper>
      </DesktopContainer>
    </React.Fragment>
  );
}

import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledContainer = styled(Container)`
  ${({ theme }): SerializedStyles => css`
    padding-left: ${theme.spacing(3)};
    padding-right: ${theme.spacing(3)};
    ${theme.breakpoints.up("md")} {
      padding-left: ${theme.spacing(14)};
      padding-right: ${theme.spacing(14)};
    }
  `};
`;

/* eslint-disable sort-keys-fix/sort-keys-fix */
import React from "react";
import {
  arrayOrNull,
  FooterColumnBlock,
  FooterV1 as FooterCore,
  getPathname,
  PpFooterColumnBlockItem,
} from "@italymondo/core-ui";
import { Grid } from "@mui/material";
import { formatData } from "./config";
import { useStaticFooterQuery } from "./query";

export function Footer(): React.ReactElement | null {
  const data = useStaticFooterQuery();

  if (!data) {
    return null;
  }

  const footerData = formatData(data);

  return (
    <FooterCore {...footerData}>
      <Grid
        container
        alignItems="flex-start"
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        spacing={4}
      >
        {arrayOrNull(
          data?.site?.footer?.footerNavigation?.map(
            (column): React.ReactElement | null => {
              switch (column.__typename) {
                case "CMS_LinkColumnWithHeader": {
                  const items = column.links.map(
                    (link): PpFooterColumnBlockItem => ({
                      label: link?.label?.name,
                      link: getPathname(link?.page.url),
                      text: link?.title || "",
                    })
                  );
                  return (
                    <Grid key={column.heading} item>
                      <FooterColumnBlock
                        alignItems="flex-start"
                        heading={column.heading || ""}
                        items={items}
                      />
                    </Grid>
                  );
                }
                default:
                  return null;
              }
            }
          )
        )}
      </Grid>
    </FooterCore>
  );
}

import { arrayOrNull, getPathname, PpFooterV1 } from "@italymondo/core-ui";
import { CMSFooterData } from "./query";

export function formatData(data: CMSFooterData): PpFooterV1 {
  if (!data.site) {
    throw new Error("Expected a site object");
  }
  const { companydetail, footer } = data.site;
  const currentYear = new Date().getFullYear();

  return {
    copyrightText: `© italyMONDO! 2006 - ${currentYear}`,
    currentSite: data.sites.find(
      (site) => site?.hostname === "www.italymondo.com"
    )?.siteName,
    description: footer?.companyDescription || "",
    legalIndexPage: {
      title: data.legalIndexPage[0].listingTitle || "",
      url: getPathname(data.legalIndexPage[0].url),
    },
    locations: [
      {
        country: companydetail?.office1Country || "",
        fullAddress: companydetail?.office1FullAddress || "",
      },
      {
        country: companydetail?.office2Country || "",
        fullAddress: companydetail?.office2FullAddress || "",
      },
    ],
    logo: {
      alt: "italymondo",
      src: companydetail?.siteLogoFooter.url || "",
    },
    memberships: arrayOrNull(
      footer?.memberships?.map((membership) => {
        switch (membership.__typename) {
          case "CMS_MembershipBlock":
            return {
              src: membership.logo.url,
              url: membership.source,
            };

          default:
            return null;
        }
      })
    ),
    sites: arrayOrNull(
      data.sites
        .filter((site) => site?.hostname === "www.italymondo.com")
        .map((site) => ({
          siteName: site.siteName,
          url: site.rootPage?.url || "",
        }))
    ),
    social: {
      facebook: companydetail?.facebook,
      instagram: companydetail?.instagram,
      twitter: companydetail?.twitter,
    },
  };
}

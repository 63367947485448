import { graphql, useStaticQuery } from "gatsby";
import { FooterQuery } from "./query.generated";

export type CMSFooterData = FooterQuery["cms"];

export function useStaticFooterQuery(): CMSFooterData {
  const data = useStaticQuery<FooterQuery>(
    graphql`
      query Footer {
        cms {
          legalIndexPage: pages(
            contentType: "italymondo_home.IMLegalIndexPage"
          ) {
            ... on CMS_IMLegalIndexPage {
              listingTitle
              url
            }
          }
          site(hostname: "www.italymondo.com") {
            companydetail {
              facebook
              instagram
              office1Country
              office1FullAddress
              office2Country
              office2FullAddress
              siteLogoFooter {
                url
              }
              twitter
            }
            footer {
              companyDescription
              footerLinks {
                ... on CMS_LinkBlock {
                  __typename
                  page {
                    url
                  }
                  title
                }
              }
              footerNavigation {
                ... on CMS_LinkColumnWithHeader {
                  __typename
                  heading
                  id
                  links {
                    page {
                      url
                    }
                    title
                  }
                }
              }
              memberships {
                ... on CMS_MembershipBlock {
                  __typename
                  logo {
                    url
                  }
                  source
                }
              }
            }
          }
          sites {
            hostname
            rootPage {
              url
            }
            siteName
          }
        }
      }
    `
  );
  return data.cms;
}

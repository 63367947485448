import { useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

export function useScrollProgress(): string {
  const [pageScrollProgress, setPageScrollProgress] = useState<string>("-100%");

  useScrollPosition(
    ({ currPos }) => {
      const winHeightPx =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = `${(Math.abs(currPos.y) / winHeightPx) * 100 - 100}%`;
      setPageScrollProgress(scrolled);
    },
    [pageScrollProgress]
  );

  return pageScrollProgress;
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
import React from "react";
import { Stack, Typography } from "@mui/material";
import { MenuCloseIcon } from "../icons";
import { AlertBarCloseIcon, AlertBarLink, Container } from "./styles";

export type PpAlertBar = {
  link: {
    text: string;
    url: string;
  };
  onHide: () => void;
  text: string;
};

export function AlertBar({
  link,
  onHide,
  text,
}: PpAlertBar): React.ReactElement {
  return (
    <Container>
      <Stack direction={{ xs: "column", md: "row" }}>
        <Typography color="site.alertBar" variant="alertBar">
          {text}
        </Typography>
        <AlertBarLink color="site.alertBar" to={link.url} variant="alertBar">
          {link.text}
        </AlertBarLink>
      </Stack>
      <AlertBarCloseIcon onClick={onHide}>
        <MenuCloseIcon titleAccess="Close" />
      </AlertBarCloseIcon>
    </Container>
  );
}

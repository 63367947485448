import React from "react";
import { Stack, Typography } from "@mui/material";
import { navigate } from "gatsby";
import { Link } from "../../links";
import {
  PageListBlockContent,
  PageListBlockIcon,
  PageListBlockItem,
  PageListBlockLinkContainer,
} from "../styles";

type PpMenuBlockPageList = {
  ctaLink: {
    text: string;
    url: string;
  };
};

type PpMenuBlockPageListItem = {
  heading: string;
  icon: string;
  shortDesc: string;
  url: string;
};

export function MenuBlockPageListItem({
  heading,
  icon,
  shortDesc,
  url,
}: PpMenuBlockPageListItem): React.ReactElement {
  return (
    <PageListBlockItem onClick={(): Promise<void> => navigate(url)}>
      <PageListBlockIcon src={icon} />
      <PageListBlockContent>
        <Typography gutterBottom color="primary" component="h5" variant="h5">
          {heading}
        </Typography>
        <Typography color="primary" component="p" variant="body2">
          {shortDesc}
        </Typography>
      </PageListBlockContent>
    </PageListBlockItem>
  );
}

export function MenuBlockPageList({
  children,
  ctaLink,
}: PpWC<PpMenuBlockPageList>): React.ReactElement | null {
  return (
    <Stack alignItems={{ sm: "stretch", xs: "flex-start" }} spacing={1}>
      {children}
      {ctaLink && (
        <PageListBlockLinkContainer>
          <Link color="primary" to={ctaLink.url} variant="caption">
            {ctaLink.text}
          </Link>
        </PageListBlockLinkContainer>
      )}
    </Stack>
  );
}

import React from "react";
import {
  arrayOrNull,
  getPathname,
  Header as HeaderCore,
  useScrollAtTop,
  useStore,
} from "@italymondo/core-ui";
import { useStaticHeaderQuery } from "./query";
import { PossibleMainMenuBlocks, renderNavItem } from "./renderNavItem";

export type PpAlertBar = {
  link: {
    text: string;
    url: string;
  };
  text: string;
};

type PpHeader = {
  alertBar?: PpAlertBar | null;
  showProgressBar: boolean;
  transparent: boolean;
};

export function Header({
  alertBar,
  showProgressBar,
  transparent,
}: PpHeader): React.ReactElement | null {
  const { hideAlertBar, showAlertBar } = useStore();
  const atTop = useScrollAtTop();

  const site = useStaticHeaderQuery();

  if (!site) {
    return null;
  }

  const isTransparent = atTop && transparent;

  const alertBarData =
    showAlertBar && alertBar
      ? {
          onHide: hideAlertBar,
          ...alertBar,
        }
      : null;

  return (
    <HeaderCore
      alertBar={alertBarData}
      atTop={atTop}
      ctaButton={{
        label: site.mainmenu?.ctaTitle,
        url: getPathname(site.mainmenu?.ctaLink?.url),
        variant: {
          desktop: "contained",
          mobile: "outlined",
        },
      }}
      logo={{
        alt: "italymondo",
        default: isTransparent
          ? site?.companydetail?.siteLogoMobile?.url || ""
          : site?.companydetail?.siteLogoDefault?.url || "",
        menuLogo: site?.companydetail?.siteLogoDefault?.url || "",
      }}
      showProgressBar={showProgressBar}
      transparentBg={transparent}
    >
      {arrayOrNull(
        site.mainmenu?.mainMenu?.map((mainMenuItem) =>
          renderNavItem({
            isTransparent,
            navItem: mainMenuItem as PossibleMainMenuBlocks,
            showProgressBar,
          })
        )
      )}
    </HeaderCore>
  );
}

import React, { useCallback } from "react";
import { Typography, TypographyProps } from "@mui/material";
import ReactMarkdown from "markdown-to-jsx";
import { Link } from "../links";

type PpRichText = TypographyProps & {
  color?: TypographyProps["color"];
  component?: React.ElementType;
  text: string;
  variant?: TypographyProps["variant"];
};

export function RichText({
  color = "inherit",
  component = "p",
  text,
  variant = "inherit",
  ...rest
}: PpRichText): React.ReactElement {
  const P = useCallback(
    (props): React.ReactElement => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (props && props.children && props.children.length === 0) return <br />;
      return (
        <Typography
          color={color}
          component={component}
          variant={variant}
          {...props}
        />
      );
    },
    [color, component, variant]
  );

  const A = useCallback(
    ({ href, ...other }): React.ReactElement | null =>
      href ? (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <Link
          highlighted
          color={color}
          to={href}
          variant={variant}
          {...other}
        />
      ) : null,
    [color, variant]
  );

  // TEMPORARY SOLUTION
  const apostrophe = /&#x27;/g;

  const options = {
    forceBlock: true,
    overrides: {
      a: { component: A },
      p: {
        component: P,
      },
    },
  };

  return (
    <Typography
      color={color}
      component={ReactMarkdown}
      options={options}
      variant={variant}
      {...rest}
    >
      {text.replace(apostrophe, `'`)}
    </Typography>
  );
}

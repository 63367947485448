import React from "react";
import {
  getPathname,
  HeaderMenu,
  Link,
  MenuBlockPageList,
  MenuBlockPageListItem,
  NavItem,
} from "@italymondo/core-ui";
import { CMS_MainMenuItemBlock } from "../../../../generated/api";

export type PossibleMainMenuBlocks = CMS_MainMenuItemBlock | null;

type PpMenuItem = {
  isTransparent: boolean;
  navItem: PossibleMainMenuBlocks;
  showProgressBar: boolean;
};

export function renderNavItem({
  isTransparent,
  navItem,
  showProgressBar,
}: PpMenuItem): React.ReactElement | null {
  switch (navItem?.__typename) {
    case "CMS_MainMenuItemBlock": {
      // CMS_MainMenuItemBlock - can be link or a submenu
      if (
        Array.isArray(navItem.subMenuItems) &&
        navItem.subMenuItems.length > 0
      ) {
        const { pages } = navItem.subMenuItems.find(
          (item) => item.__typename === "CMS_MenuPageListBlock"
        );
        const { linkText, page } = navItem.subMenuItems.find(
          (item) => item.__typename === "CMS_MenuSummaryBlock"
        );

        return (
          <NavItem key={navItem.title} $transparentBg={isTransparent}>
            <HeaderMenu
              fullWidth={!isTransparent}
              label={navItem.title}
              showProgressBar={showProgressBar}
            >
              <MenuBlockPageList
                ctaLink={{ text: linkText, url: getPathname(page?.url) }}
              >
                {pages.map((subPage) => (
                  <MenuBlockPageListItem
                    key={subPage?.title}
                    heading={subPage?.title || ""}
                    icon={subPage?.icon?.url || ""}
                    shortDesc={subPage?.description || ""}
                    url={getPathname(subPage?.page.url)}
                  />
                ))}
              </MenuBlockPageList>
            </HeaderMenu>
          </NavItem>
        );
      }
      return (
        <NavItem key={navItem.title} $transparentBg={isTransparent}>
          <Link
            activeClassName="active-link"
            to={getPathname(navItem.page?.url)}
          >
            {navItem.title}
          </Link>
        </NavItem>
      );
    }
    default:
      return null;
  }
}

import React from "react";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";

export type PpButton = MuiButtonProps;

export type TpLinkButton = {
  page: {
    url: string;
  };
  title: string;
} | null;

export function Button(props: PpButton): React.ReactElement {
  return <MuiButton {...props} />;
}
